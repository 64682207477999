<template>
  <el-dialog
    class="dialog"
    modal-append-to-body
    :title="formTitle"
    :close-on-click-modal="false"
    :visible.sync="formVisible"
  >
    <div class="box">
      <div class="left">
        <el-form
          ref="dataForm"
          :rules="rules"
          :model="formData"
          label-position="left"
        >
          <div class="l-cont">
            <el-form-item label="登录账号1" prop="username">
              <el-input v-model="formData.username" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item v-if="showPassword" label="登录密码" prop="password">
              <el-input
                v-model="formData.password"
                show-password
                placeholder="请输入密码"
              />
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input v-model="formData.name" placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
              <el-input v-model="formData.phone" placeholder="请输入电话号码" />
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="formData.email" placeholder="请输入邮箱" />
            </el-form-item>
            <el-form-item label="账号状态">
              <el-radio v-model="formData.status" :label="0">开启</el-radio>
              <el-radio v-model="formData.status" :label="1">关闭</el-radio>
            </el-form-item>
            <el-form-item label="所属代理商">
              <el-select v-model="formData.type" placeholder="请选择代理商">
                <!-- <el-option value="createUser" label="创建普通用户"></el-option>
                <el-option value="admin" label="管理员"></el-option> -->
                <el-option
                  v-for="item in typeList"
                  :value="item.username"
                  :label="
                    '代理商:' + item.username + '（手机号：' + item.phone + ')'
                  "
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="margin-top: 10px">
          <el-button
            size="medium"
            class="confirm-btn"
            @click="formVisible = false"
            >取消</el-button
          >
          <el-button
            size="medium"
            class="confirm-btn"
            type="primary"
            @click="submit"
            >提交</el-button
          >
        </div>
      </div>
      <div class="right">
        <Imgpond
          class="img"
          v-model="formData.icon"
          :count="1"
          @success="imgSuccess"
        />
        <span class="title">头像</span>
        <span class="tip">（建议上传正方形图片）</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import http from "@/utils/cloud";
import global from "@/config/global";

export default {
  data() {
    return {
      showPassword: true,
      formVisible: false, //表单显示状态
      formData: {
        types: [],
        resource: true,
      }, //表单数据
      typeList: [],
      endTime: "",
      rules: {
        username: [
          { required: true, message: "请输入登录账号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        type: [
          { required: true, message: "请选择所属代理商", trigger: "blur" },
        ],
      },
    };
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    formTitle() {
      return this.formData._id ? "修改用户" : "新增用户";
    },
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            name: "",
            status: 0,
          };
          this.showPassword = true;
        } else {
          this.showPassword = false;
        }
      } else {
        this.$refs.dataForm.resetFields();
        this.formData = {};
      }
    },
  },
  created() {
    this.getAgent();
  },
  methods: {
    // 获取代理商
    async getAgent() {
      const res = await http.GET("/jw-admin", {
        module: "admin",
        operation: "getAgent",
        type: "agent",
      });
      this.typeList = res;
    },
    submit() {
      // 是否是demo演示环境
      if (global.demo) {
        this.$message({
          message: "当前是演示环境，此设置只可参看，无法修改~",
          type: "warning",
          center: true,
        });
        return;
      }
      this.$refs.dataForm.validate(async (res) => {
        if (res === false) {
          return;
        }
        const operation = this.formData._id
          ? "updateUserInfo"
          : "addCreateUser";
        const data = this.formData;
        console.log(data);
        const response = await http.POST("/jw-admin", {
          module: "admin",
          operation: operation,
          data,
        });
        if (response.status === 1) {
          this.$message.success(response.msg);
          this.$emit("refreshData");
          this.formVisible = false;
        } else {
          this.$message.error(response.msg);
        }
      });
    },
    async imgSuccess(base64) {
      let result = await this.$aliOSS(base64);
      if (result) {
        console.log(result.url);
        this.formData.icon = result.url;
      } else {
        this.$message.error("图片失败");
        this.formData.image = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 820px;
    max-width: 820px;
  }
  ::v-deep .el-dialog__header {
    background: #eef1f6;
    border-radius: 10px 10px 0 0;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep el-form-item__label {
    line-height: 30px;
  }
  .el-select {
    width: 260px;
  }
  .box {
    display: flex;
    .left {
      width: calc(100% - 190px);
      padding: 30px 15px;
      .l-cont {
        display: flex;
        flex-wrap: wrap;
        .el-form-item {
          max-width: 50%;
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          margin-bottom: 15px;
          .el-input {
            width: 260px;
            height: 36px;
            line-height: 36px;
          }
        }
        .time {
          .el-form-item__content {
            position: relative;
            display: flex;
            .el-checkbox {
              position: absolute;
              right: 50px;
              top: -40px;
            }
            .is-checked {
              ::v-deep .el-checkbox__inner {
                background: #6954f0;
                border-color: #6954f0;
              }
              ::v-deep .el-checkbox__label {
                color: #20303f;
              }
            }
          }
        }
      }
    }
    .right {
      width: 190px;
      padding-top: 40px;
      text-align: center;
      background: #f7f8fa;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 98px;
        height: 98px;
        line-height: 98px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
      }
      ::v-deep .el-upload-list__item {
        width: 98px !important;
        height: 98px !important;
        border: none;
        border-radius: 50%;
        margin: 0;
        > img {
          width: 98px;
          height: 98px;
        }
      }
      ::v-deep .el-upload--picture-card {
        width: 98px !important;
        height: 98px !important;
        border: none;
        border-radius: 50%;
        margin: 0;
        background: url("https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673942464190ce.png")
          100% 100% no-repeat;
        background-size: cover;
        i {
          display: none;
        }
      }
      .title {
        margin-top: 15px;
        font-size: 14px;
        color: #20303f;
      }
      .tip {
        margin-top: 10px;
        font-size: 13px;
        color: #adbac6;
      }
    }
  }
}
.tip {
  font-size: 11px;
  color: #ff4443;
}
</style>
